import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ApiService } from '../../app.service';

@Component({
    selector: 'ENCI_CalendarSection',
    templateUrl: './ENCI_CalendarSection.component.html'
})
export class ENCI_CalendarSectionComponent implements OnInit {

    public filereg1: string;
    public filereg2: string;
    public filereg3: string;
    public filereg4: string;
    public filereg5: string;
    public filereg6: string;
    public filereg7: string;
    public filereg8: string;
    public filereg9: string;
    public filereg10: string;
    public filereg11: string;
    public filereg12: string;
    public filereg13: string;

    constructor(private apiService: ApiService) {
    }
    public async ngOnInit() {
        const serverdata = forkJoin(
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg1" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg2" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg3" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg4" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg5" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg6" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg7" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg8" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg9" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg10" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg11" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg12" }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "filereg13" })
        ).subscribe(([f1, f2, f3, f4, f5, f6, f7, f8, f9, f10, f11, f12, f13]) => {
            this.filereg1 = f1.dto;
            this.filereg2 = f2.dto;
            this.filereg3 = f3.dto;
            this.filereg4 = f4.dto;
            this.filereg5 = f5.dto;
            this.filereg6 = f6.dto;
            this.filereg7 = f7.dto;
            this.filereg8 = f8.dto;
            this.filereg9 = f9.dto;
            this.filereg10 = f10.dto;
            this.filereg11 = f11.dto;
            this.filereg12 = f12.dto;
            this.filereg13 = f13.dto;
        });

    }

}
